<template>
   <div class="row">
      <div class="col-12">
         <div class="card">
            <div class="card-body pb-0">
               <div class="row m-0">
                  <div class="col-12 px-1 mb-3" :class="pesquisa.tipo == 'Sem estoque' ? 'col-md-9 col-xl-9' : 'col-sm-6 col-md-3 col-xl-3'">
                     <label><i class="far fa-stream color-theme font-12 me-1"></i> Tipo de pesquisa</label>
                     <v-select :options="['Nome do produto', 'Categoria', 'Subcategoria', 'Sem estoque']" v-model="pesquisa.tipo" placeholder="Selecione uma opção" />
                  </div>
                  <div class="col-12 col-sm-6 px-1 mb-3"  v-if="pesquisa.tipo == 'Categoria'">
                     <label><i class="far fa-file color-theme font-12 me-1"></i> Categoria</label>
                     <v-select :options="listaCategorias" v-model="pesquisa.categoria" label="categoria" placeholder="Selecione uma opção" />
                  </div>
                  <div class="col-12 col-sm-6 col-md-3 col-xl-3 px-1 mb-3" v-if="pesquisa.tipo == 'Subcategoria'">
                     <label><i class="far fa-file color-theme font-12 me-1"></i> Categoria</label>
                     <v-select :options="listaCategorias" v-model="pesquisa.categoria" label="categoria" placeholder="Selecione uma opção" @input="buscarSubcategorias(pesquisa.categoria)" />
                  </div>
                  <div class="col-12 col-md-3 col-xl-3 px-1 mb-3" v-if="pesquisa.tipo == 'Subcategoria'">
                     <label><i class="far fa-copy color-theme font-12 me-1"></i> Subcategoria</label>
                     <v-select :options="subcategorias" v-model="pesquisa.subcategoria" placeholder="Selecione uma opção" />
                  </div>
                  <div class="col-12 col-sm-6 col-xl-6 px-1 mb-3" v-if="pesquisa.tipo == 'Nome do produto' || pesquisa.tipo == null || pesquisa.tipo == ''">
                     <label><i class="far fa-apple-alt color-theme font-12 me-1"></i> Nome do produto</label>
                     <input type="text" class="form-control" v-model="pesquisa.nomeProduto" maxlength="50" v-focus placeholder="Nome do produto" @keyup.enter="buscarProdutos" />
                  </div>
                  <div class="col-5 col-md-1 px-1 mb-3 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="buscarProdutos">
                        <i class="fas fa-search font-13"></i>
                     </button>
                  </div>
                  <div class="col-7 col-md-2 col-xl-2 px-1 mb-3 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="abrirAddProduto">
                        <i class="fas fa-plus font-13 me-1"></i> Adicionar
                     </button>
                  </div>
                  <div class="col-12 text-end font-16 color-theme mb-2">
                     <i class="fal fa-th-large cursor-pointer" title="Quadrado" :class="produtosView != 'Quadrado' ? 'text-secondary' : ''" @click="toggleView('Quadrado')"></i>
                     <i class="fal fa-th-list ms-3 cursor-pointer" title="Linha" :class="produtosView != 'Linha' ? 'text-secondary' : ''" @click="toggleView('Linha')"></i>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="resultado.length != 0">
         <div :class="produtosView == 'Linha' ? 'row m-0' : 'cards'">
            <produto v-for="(produto, index) in resultado" :key="index" :produto="produto" :index="index" :view="produtosView" :page="'PRODUTOS'" @abrirEditarProduto="abrirEditarProduto($event)" />
         </div>
      </div>

      <div class="col-12 py-5 text-center" v-else>Nenhum produto encontrado</div>

      <!-- modalSalvarProduto -->
      <div class="modal fade" id="modalSalvarProduto" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalSalvarProdutoLabel" aria-hidden="true">
         <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalSalvarProdutoLabel" v-if="produto.id == null">Adicionar produto</h5>
                  <h5 class="modal-title" id="modalSalvarProdutoLabel" v-else>Editar produto</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="card mx-1 mb-2">
                     <div class="card-body">
                        <div class="row">
                           <div class="col-12 col-md-6 mb-2">
                              <label><i class="far fa-apple-alt color-theme font-12 me-1"></i> Nome</label> 
                              <input type="text" class="form-control" v-model="produto.nome" maxlength="50">
                           </div>
                           <div class="col-12 col-md-6 mb-2">
                              <label><i class="far fa-map-marker-alt color-theme font-12 me-1"></i> Nome spawn</label> 
                              <input type="text" class="form-control" v-model="produto.nomeSpawn" maxlength="50">
                           </div>
                           <div class="col-12 col-md-6 mb-2">
                              <label><i class="far fa-stream color-theme font-12 me-1"></i> Tipo</label> 
                              <input type="text" class="form-control" v-model="produto.tipo">
                           </div>
                           <div class="col-12 col-md-6 mb-2">
                              <label><i class="far fa-dollar-sign color-theme font-12 me-1"></i> Preço</label> 
                              <input type="text" class="form-control" v-model="produto.preco" v-mask="['#.##', '##.##', '###.##', '####.##', '#####.##']">
                           </div>
                           <div class="col-12 col-md-6 mb-2">
                              <label><i class="far fa-file color-theme font-12 me-1"></i> Categoria</label> 
                              <v-select :options="listaCategorias" label="categoria" placeholder="Selecione uma opção" v-model="produto.categoria" @input="buscarSubcategorias(produto.categoria)" />
                           </div>
                           <div class="col-12 col-md-6 mb-2">
                              <label><i class="far fa-copy color-theme font-12 me-1"></i> Subcategoria</label> 
                              <v-select :options="subcategorias" placeholder="Selecione uma opção" v-model="produto.subcategoria" />
                           </div>
                           <div class="col-12 col-md-6 mb-2">
                              <label><i class="far fa-ellipsis-h color-theme font-12 me-1"></i> Descrição</label> 
                              <textarea type="text" class="form-control shadow-none" v-model="produto.descricao" maxlength="250"></textarea>
                           </div>
                           <div class="col-12 col-md-6 mb-2">
                              <label><i class="far fa-box color-theme font-12 me-1"></i> Quantidade em estoque</label> 
                              <input type="text" class="form-control" v-model="produto.estoque" v-mask="'###############'">
                           </div>
                           <div class="col-12 col-lg-6 mb-2">
                              <div class="form-group file-area">
                                 <label for="images"><i class="fal fa-image color-theme font-12 me-1"></i> Foto</label>
                                 <input type="file" name="images" id="images" ref="images" accept=".jpg,.jpeg,.png,.webp" @change="onFileChange" @drop="onFileChange" @click="onFileChange" />
                                 <div class="file-dummy">
                                    <div class="default">Clique para selecionar uma foto</div>
                                 </div>
                              </div>
                              <button class="btn btn-primary w-max-content mt-2 float-end" @click="addFotoTemp" v-if="image != null">
                                 <i class="fal fa-plus font-13 me-1"></i> Adicionar
                              </button>
                           </div>

                           <div class="col-12 col-lg-6 mt-4 position-relative" v-if="image != null">
                              <div class="cropper-box">
                                 <cropper class="cropper" :src="image" :canvas="{ minHeight: 60, minWidth: 60, maxHeight: 300, maxWidth: 300 }" :stencil-props="{ minAspectRatio: 16/16, maxAspectRatio: 4/4 }" :auto-zoom="true" :backgroundClass="'bg-white'" @change="change" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="row m-0" v-if="produto.fotos.length > 0">
                     <div class="col-lg-3 col-md-4 col-sm-6 px-1" v-for="(foto, index) in produto.fotos" :key="index">
                        <div class="card">
                           <div class="card-body p-2">
                              <div class="bg-white border cursor-pointer pb-1 position-absolute pt-2 px-2 right-7 rounded" title="Excluir foto" @click="excluirFotoTemp(index)">
                                 <i class="fas fa-trash text-danger font-16"></i>
                              </div>
                              <img class="w-100" :src="foto.foto" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarProduto">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import produto from '@/components/admin/produtos/Produto.vue'

export default {
	name: 'AdmProdutos',
   data: function () {
      return {
         produtosView: localStorage.produtosView == undefined ? 'Linha' : localStorage.produtosView,
         pesquisa: {'tipo': 'Nome do produto', 'categoria': null, 'subcategoria': null, 'nomeProduto': ''},
         produto: {'id': null, 'nome': null, 'categoria': null, 'fotos': [], 'estoque': '0', 'preco': '0.00', 'quantidadeVendida': null, 'nomeSpawn': null, 'descricao': null, 'tipo': null},
         subcategorias: [],
         resultado: [],
         image: null,
         imageTemp: null
      }
   },
   computed: {
      ... mapState({
         listaCategorias: state => state.listaCategorias,
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   components: {
      produto
   },
   methods: {
      buscarSubcategorias : function (categoria) {
			this.listaCategorias.forEach(c => {
				if (c.categoria == categoria.categoria) {
					this.subcategorias = c.subcategorias

					if (this.produto.id == null) {
						this.pesquisa.subcategoria = c.subcategorias[0]
					}
				}
			});
      },
      buscarProdutos : function () {
         if (this.pesquisa.tipo == null || this.pesquisa.tipo == '') {
            this.pesquisa.tipo = 'Nome do produto'
         } 
         
         if ((this.pesquisa.tipo == 'Categoria' && this.pesquisa.categoria == null) || 
            (this.pesquisa.tipo == 'Subcategoria' && this.pesquisa.subcategoria == null)) {
            
            this.$toast.fire({
               icon: 'error',
               title: 'Selecione uma '+ this.pesquisa.tipo
            });

            return
         }

			let valor = '';

			switch (this.pesquisa.tipo) {
				case 'Nome do produto':
					valor = this.pesquisa.nomeProduto
				break;
				case 'Categoria':
					valor = this.pesquisa.categoria.categoria
				break;
				case 'Subcategoria':
					valor = this.pesquisa.subcategoria
				break;
			}

         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.resultado = []

         this.$axios({
            method: 'get',
            url: this.urlRest +'adminproduct/searchproducts',
				headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername 
            },
            params: {
               tipo: this.pesquisa.tipo,
               valor: valor
            }
         }).then(response => {
            this.resultado = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      toggleView : function (view) {
         localStorage.produtosView = view
         this.produtosView = view
      },
      addFotoTemp : function () {
         this.produto.fotos.push({'id': Math.floor(Math.random() * 999999999), 'foto': this.imageTemp})

         this.$refs.images.value = null;
         this.image = null;
         this.imageTemp = null;
      },
      excluirFotoTemp : function (index) {
         this.produto.fotos.splice(index, 1)
      },
      salvarProduto : function () {
         if (this.produto.nome == null || String(this.produto.nome).length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Nome inválido!'
            })

            return
         } else if (this.produto.categoria == null || String(this.produto.categoria).length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Categoria inválida!'
            })

            return
         } else if (this.produto.estoque == null || String(this.produto.estoque).length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Estoque inválido!'
            })

            return
         } else if (this.produto.preco == null || String(this.produto.preco).length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Preço inválido!'
            })

            return
         }

         let ref = this;
			this.$store.dispatch('alternarTelaCarregamento', true)

			if (this.produto.categoria.categoria != undefined) {
				this.produto.categoria = this.produto.categoria.categoria
			}

         this.$axios({
            method: 'post',
            url: this.urlRest +'adminproduct/saveproduct',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername,
					'Content-Type': 'application/json'
            },
				data: this.produto

         }).then(function () {
				$('#modalSalvarProduto').modal('hide')
            ref.produto = {'id': null, 'nome': null, 'categoria': null, 'fotos': [], 'estoque': '0', 'preco': '0.00', 'quantidadeVendida': null, 'nomeSpawn': null, 'descricao': null, 'tipo': null}

            ref.$toast.fire({
               icon: 'success',
               title: 'Produto salvo!'
            })
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
				this.buscarProdutos()
         })
      },
		abrirAddProduto : function () {
         this.image = null
			this.produto = {'id': null, 'nome': null, 'categoria': null, 'fotos': [], 'estoque': '0', 'preco': '0.00', 'quantidadeVendida': null, 'nomeSpawn': null, 'descricao': null, 'tipo': null}

			$('#modalSalvarProduto').modal('show')
		},
		abrirEditarProduto : function (produto) {
			this.produto = {
				'id': produto.id, 
				'nome': produto.nome, 
				'categoria': produto.categoria, 
				'subcategoria': produto.subcategoria, 
				'fotos': produto.fotos, 
				'estoque': produto.estoque, 
				'preco': parseFloat(produto.preco).toFixed(2), 
				'quantidadeVendida': produto.quantidadeVendida,
            'nomeSpawn': produto.nomeSpawn, 
            'descricao': produto.descricao, 
            'tipo': produto.tipo
			}

			this.buscarSubcategorias(produto)
         this.image = null

			$('#modalSalvarProduto').modal('show')
		},
      change : function ({ coordinates, canvas }) {
         coordinates;
         this.imageTemp = canvas.toDataURL('image/jpeg');
		},
      onFileChange(e) {
         let files = e.target.files || e.dataTransfer.files;

         if (!files.length)
         return;

         this.createImage(files[0]);
      },
      createImage(file) {
         let reader = new FileReader();
         let vm = this;

         reader.onload = (e) => {
            vm.image = e.target.result;
         }

         reader.readAsDataURL(file);
      }
   }
}

</script>

<style scoped>

.fa-th-large, .fa-th-list {
   transform: translateY(-3px) scale(1.10);
}

.fa-th-large:hover, .fa-th-list:hover {
   transform: translateY(-3px) scale(1.20);
}

.form-check-input[type=checkbox] {
   border-radius: 0.25em;
   height: 20px;
   width: 20px;
   margin-right: 12px;
   margin-left: 0;
}

</style>